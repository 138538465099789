import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'ISP Diagram' },
    { img: data.i2, caption: 'ISP Full panel installed' },
    { img: data.i3, caption: 'ISP Corner Bracket Diagram' },
    { img: data.i4, caption: 'ISP Floor to Floor bolt-through' },
    { img: data.i5, caption: 'Steel K-Frame' },
    { img: data.i6, caption: 'Steel K-Frames at Stairwell' },
    { img: data.i7, caption: 'Reinforced CMU Shear Towers for Lateral' },
    { img: data.i8, caption: 'K Frames & CMU Towers' },
    { img: data.i9, caption: 'Concrete Stair & Elevator Towers' },
    { img: data.i10, caption: 'Deck Support Angle on Concrete Walls' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: Lateral Loads Wind and Seismic Infinity Shear Panels" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>
          Lateral Loads / Wind and Seismic: Infinity Shear Panels
        </h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>The lateral loads (wind and seismic) may be handled with the Infinity Shear
            Panel, light-gauge X-strapping, steel K-frames, reinforced CMU or, concrete
            shear walls. Each project is unique in its design and location, therefore the
            most economical and "user friendly" approach is usually determined through
            early consultation between Infinity and the Structural Engineer of Record.</p>
            <p className='type--lg'>
              <Link className="link-next" to="/system-overview/epicore-msr-floor/">
                Next Page: EPICORE MSR Composite Floor
              </Link>
            </p>
            <p>
              <Link className="link-prev" to="/system-overview/installing-wall-panels/">
                Prev Page: Installing Wall Panels
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-overview/lateral-loads/gallery/01-41616.png" }) {
            ...SystemOverviewCarouselTechnical1_5ImageFragment },
        i2: file(relativePath: { eq: "system-overview/lateral-loads/gallery/02-ISPSP120_(33944).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i3: file(relativePath: { eq: "system-overview/lateral-loads/gallery/03-41617.png" }) {
            ...SystemOverviewCarouselTechnical1_5ImageFragment },
        i4: file(relativePath: { eq: "system-overview/lateral-loads/gallery/04-ISPSP110_(33936).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i5: file(relativePath: { eq: "system-overview/lateral-loads/gallery/05-LATES040_(41311).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i6: file(relativePath: { eq: "system-overview/lateral-loads/gallery/06_LATES050_(41312).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i7: file(relativePath: { eq: "system-overview/lateral-loads/gallery/07-LATES080_(40664).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i8: file(relativePath: { eq: "system-overview/lateral-loads/gallery/08-LATES010_(41274).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i9: file(relativePath: { eq: "system-overview/lateral-loads/gallery/09-LATES100_(42894).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i10: file(relativePath: { eq: "system-overview/lateral-loads/gallery/10-LATES130_(42897).png" }) {
            ...SystemOverviewCarouselImageFragment },
    }
`
